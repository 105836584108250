<template>
    <div>
        <div class="wrap">
            <div class="top-area">
                <div class="title">Notice</div>
            </div>
        </div>
        <div class="winex-notice-list-wrap">
                <div class="pc-notice-list notice-list-wrap">
                    <div class="notice-list">

                        <div class="notice-item" v-for="notice in viewNoticeList" v-bind:key="notice.notice_no" @click="moveDetail(notice.notice_no)">
                            <div class="notice-thumbnail" :style="{backgroundImage: `url(${notice.notice_thumbnail_image})`}"></div>
                            <div class="notice-date">{{notice.created_at}}</div>
                            <div class="notice-subject">{{notice.notice_title}}</div>
                        </div>

                    </div>
                    <WinexPagination
                            :current-page="pageInfo.current_page"
                            :total-page="pageInfo.total_page"
                            :move-page="movePage"></WinexPagination>

                </div>
                <div class="mobile-notice-list notice-list-wrap">
                    <div class="notice-list">

                        <div class="notice-item" v-for="notice in viewNoticeList" v-bind:key="notice.notice_no" @click="moveDetail(notice.notice_no)">
                            <div class="notice-thumbnail" :style="{backgroundImage: `url(${notice.notice_thumbnail_image})`}"></div>
                            <div class="notice-date">{{notice.created_at}}</div>
                            <div class="notice-subject">{{notice.notice_title}}</div>
                        </div>
                    </div>

                    <infinite-loading @infinite="nextPage">
                        <div slot="no-results">
                            <!-- 검색 결과 없을 때 -->
                            <!--<span>결과 없어용..!</span>-->
                        </div>
                        <div slot="no-more">
                            <!-- 검색 결과 더이상 없을 때 -->
                            <!--<span>더이상 결과 없어용..!</span>-->
                        </div>
                    </infinite-loading>
                </div>
            </div>
    </div>
</template>

<script>
    import WinexPagination from "../../components/paginator/WinexPagination";
    export default {
        name: "Notice",
        components: {WinexPagination},
        data() {
            return {
                pageInfo: {
                    current_page:0,
                    total_page:0
                },
                noticeList:[
                    {
                        no:1,
                        thumbnail:'https://github.com/kkotkkio/kk-storage/blob/main/images/winex/membership_club_01.png?raw=true',
                        date:'2022.07.05',
                        subject:'세계 최초 파인 와인 NFT 거래소 와넥스 멤버쉽 Lazy Cat Wine Club(LCWC) 민팅 일정'
                    },
                    {
                        no:2,
                        thumbnail:'https://winex-publish.s3.ap-northeast-2.amazonaws.com/sample_image/notice-minting-thumbnail.png',
                        date:'2022.07.05',
                        subject:'와넥스 와인민팅 예정공지'
                    }

                ],
                secondNoticeList:[
                ],
                viewNoticeList:[]
            }
        },
        created() {

        },
        mounted() {
            this.getNoticeList()
        },
        methods: {
            getNoticeList() {
                this.$store.dispatch('notice/getNoticeList', {take:12, page:this.pageInfo.current_page}).then((data) => {
                    this.pageInfo.current_page = data.current_page
                    this.pageInfo.total_page = data.last_page
                    this.viewNoticeList = data.data
                })
            },
            getNoticeListMobile() {
                this.$store.dispatch('notice/getNoticeList', {take:12, page:this.pageInfo.current_page}).then((data) => {
                    this.pageInfo.current_page = data.current_page
                    this.pageInfo.total_page = data.last_page
                    this.viewNoticeList.push(...data.data)
                })
            },
            moveDetail(no) {
                this.$router.push('/cs/notice/'+no);
            },
            movePage(page) {
                if(page !== this.pageInfo.current_page) {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
                this.pageInfo.current_page = page
                this.getNoticeList()
            },
            nextPage($state) {
                // https://peachscript.github.io/vue-infinite-loading/guide/start-with-hn.html
                // 통신 연동 부분
                if(this.pageInfo.current_page < this.pageInfo.total_page) {
                    this.pageInfo.current_page ++
                    this.getNoticeListMobile()
                    $state.loaded(); // 다음 페이지가 있을 경우
                } else {
                    //alert('마지막 페이지 입니다.')
                    $state.complete(); // 다음 페이지가 없을 경우
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/assets/scss/notice-list.scss";
</style>
